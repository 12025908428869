@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-title: 'Montserrat', sans-serif;
  --font-content: 'Noto Sans', sans-serif;
}

* {
  scroll-behavior: smooth;
}

@layer utilities {
  html {
    font-family: var(--font-content);
  }
  .flex-center {
    @apply flex justify-center items-center;
  }

  .flex-between {
    @apply flex justify-between items-center;
  }
  .title {
    font-family: var(--font-title);
  }
}
